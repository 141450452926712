/* eslint-disable react-hooks/exhaustive-deps */
import {useDispatch, useSelector} from 'react-redux';
import {
  setCurrentAnalysisRunId,
  setCurrentSelectedAdvancedFactorValue,
} from '../../../state/slice/application-data-slice';
import {RootState} from '../../../state/store';
import {AdvancedFactorCurrentValue} from '../../../types/metrics';
import {useDataPointsV2} from './useDataPointsV2';
import {BASE_RUN, IN_PROGRESS_ADV_FACTOR_RUN, IN_PROGRESS_BASE_RUN, NOT_COMPUTED_RUN,} from '../../../types/jobs';
import store from '../../../state/init-store';
import {useGetTasksForModelIdQuery} from '../../../state/api/task-service-api';
import {useEffect, useRef} from 'react';
import {useEnvironment} from "../useEnvironmentAndUserInfo";
import {Environment} from "../../../analysis/dashboard.models";

const unSupportedSimulationFactorIdsStgProd = [
  'parameters.TotalCarbonFactoryParameters:658ab937162840609a6afd2f1415a569-1.0.0',
  'parameters.TotalCarbonFactoryParameters:e39ea4e1f75243daa80bb8c584ee0cd1-1.0.0',
  'parameters.TotalCarbonFactoryParameters:ab6ca5d13d124d08afeb9d1f25a27025-1.0.0',
  'parameters.TotalCarbonFactoryParameters:2939f28226d2466c83c45cfda82369b7-1.0.0',
  'parameters.TotalCarbonFactoryParameters:2b3db1963d5f4d1d9ced7c46ab18c3d9-1.0.0'
];
const unSupportedSimulationFactorIdsDev = [
  'parameters.TotalCarbonFactoryParametersDev:658ab937162840609a6afd2f1415a569-1.0.0',
  'parameters.TotalCarbonFactoryParametersDev:e39ea4e1f75243daa80bb8c584ee0cd1-1.0.0',
  'parameters.TotalCarbonFactoryParametersDev:ab6ca5d13d124d08afeb9d1f25a27025-1.0.0',
  'parameters.TotalCarbonFactoryParametersDev:2939f28226d2466c83c45cfda82369b7-1.0.0',
  'parameters.TotalCarbonFactoryParametersDev:2b3db1963d5f4d1d9ced7c46ab18c3d9-1.0.0'
];
/**
 * Set the current analysis run id
 */
export const useSetAnalysisRunV2 = (): ((runId: string) => void) => {
  const dispatch = useDispatch();

  return (runId: string) => {
    dispatch(setCurrentAnalysisRunId(runId));
  };
};

/**
 * Get the current analysis run id
 */
export const useCurrentAnalysisRunIdV2 = (): string => {
  let selectedAnalysisRun = useSelector(
    (state: RootState) => state.applicationDataState.currentAnalysisRunId
  );

  return selectedAnalysisRun;
};

/**
 * Get current advanced factor selections from state
 */
export const useCurrentAnalysisRunAdvancedFactorValueV2 = (): (() => {
  [advancedFactorId: string]: AdvancedFactorCurrentValue;
}) => {
  return () => (store.getState() as RootState).applicationDataState.currentSelectedAdvancedFactors;
};

/**
 * Set a current advanced factor selection in state
 */
export const useSetCurrentAnalysisRunAdvancedFactorValueV2 = (): ((
  advancedFactorCurrentValue: AdvancedFactorCurrentValue
) => void) => {
  const dispatch = useDispatch();

  return (advancedFactorCurrentValue: AdvancedFactorCurrentValue) => {
    dispatch(
      setCurrentSelectedAdvancedFactorValue({
        [advancedFactorCurrentValue.advancedFactorId]: advancedFactorCurrentValue,
      })
    );
  };
};

/**
 * Select the analysis run dependent on the current advanced factor selections
 */
export const useCurrentAnalysisRunSelectorV2 = (): void => {
  // TODO: improve this function to possible memoize the matching run id
  const setRunId = useSetAnalysisRunV2();
  let currentModelId = useSelector((state: RootState) => state.applicationDataState.currentModelId);
  const split = currentModelId?.split('model:');
  const strippedModelId = split?.length > 1 ? split[1] : currentModelId;
  const {data: environment} = useEnvironment();
  const isDevEnvironment = environment === Environment.Dev;

  const {
    data: tasks,
    isSuccess: tasksSuccess,
    isLoading: tasksLoading,
    isFetching: tasksFetching,
    isError: tasksError,
  } = useGetTasksForModelIdQuery(strippedModelId, {
    skip: !strippedModelId,
  });

  const {
    data,
    isError: dataError,
    isLoading: dataLoading,
    isFetching: dataFetching,
    isSuccess: dataSuccess,
  } = useDataPointsV2();

  const isFetching = tasksFetching || dataFetching;
  const isLoading = tasksLoading || dataLoading;
  const isError = dataError || tasksError;
  const success = tasksSuccess && dataSuccess;

  let currentAdvancedFactorValues = useSelector(
    (state: RootState) => state.applicationDataState.currentSelectedAdvancedFactors
  );

  const matchingRunId = useRef<string>(BASE_RUN);

  useEffect(() => {
    if (data && tasks && success && !isFetching && !isLoading && !isError) {
      const baseRun = data.analysisRuns.find((run) => run.isBaseRun);

      if (baseRun && matchingRunId.current === IN_PROGRESS_BASE_RUN) {
        matchingRunId.current = BASE_RUN;
        setRunId(matchingRunId.current);
        return;
      }

      if (baseRun) {
        let matchingRunFoundInResults = false;
        // temp block to remove unsupported advanced factors
        // currently infiltrationRate, until current base runs are updated
        const unSupportedSimulationFactorIds = isDevEnvironment ?
          unSupportedSimulationFactorIdsDev : unSupportedSimulationFactorIdsStgProd;
        
        const currentAdvancedFactorIds = data.definitions.advancedFactors
          .filter((x) => !unSupportedSimulationFactorIds.includes(x.simulationFactor))
          .map((x) => x.id);
        // stored advanced factor values from dashboard selection
        // any advanced factor values that are not set to revert to base
        let currentInputs = Object.values(currentAdvancedFactorValues).filter(
          (x) => !x.revertToBase && currentAdvancedFactorIds.includes(x.advancedFactorId)
        );
        const currentInputSimulationIds = currentInputs.map((x) => x.simulationfactorId);
        const baseRunInputs = baseRun.inputs;
        // do we need to pad out run search with base run inputs?
        const missingInputs = baseRunInputs.filter(
          (x) => !currentInputSimulationIds.includes(x.parameterId)
        );

        if (missingInputs.length) {
          // pad out the current inputs with the base run inputs if needed
          currentInputs = [
            ...currentInputs,
            ...missingInputs.map(
              (x) =>
                ({
                  advancedFactorId: '',
                  simulationfactorId: x.parameterId,
                  value: {
                    imperialStandardValue: x.imperialStandardValue,
                    industryStandardValue: x.industryStandardValue,
                  },
                  revertToBase: false,
                } as AdvancedFactorCurrentValue)
            ),
          ];
        }

        // search for matching runs in complete results
        for (const run of data.analysisRuns) {
          let matchedInputs = 0;
          let runInputs = run.inputs;
          if (runInputs.length !== currentInputs.length) {
            const runInputsSimulationIds = runInputs.map((x) => x.parameterId);
            runInputs = [
              ...runInputs,
              ...baseRunInputs.filter((x) => !runInputsSimulationIds.includes(x.parameterId)),
            ];
          }

          for (const matchInput of currentInputs) {
            const matchedInput = runInputs.find(
              (input) =>
                input.parameterId === matchInput.simulationfactorId &&
                input.imperialStandardValue.value ===
                  matchInput.value.imperialStandardValue.value &&
                input.industryStandardValue.value === matchInput.value.industryStandardValue.value
            );
            if (matchedInput) {
              matchedInputs++;
            }
          }

          if (matchedInputs === currentInputs.length) {
            matchingRunId.current = run.id === baseRun.id ? BASE_RUN : run.id.toString();
            matchingRunFoundInResults = true;
            break; // Break out of the loop when a match is found
          }
        }

        // if not found in complete results then work out if in progress
        if (!matchingRunFoundInResults && tasks?.length) {
          for (const task of tasks) {
            let matchedInputs = 0;
            let taskInputs = task?.request?.payload?.inputs;
            if (taskInputs) {
              if (taskInputs.length !== currentInputs.length) {
                const runInputsSimulationIds = taskInputs.map((x) => x.parameterId);
                taskInputs = [
                  ...taskInputs,
                  ...baseRunInputs.filter((x) => !runInputsSimulationIds.includes(x.parameterId)),
                ];
              }

              for (const matchInput of currentInputs) {
                const matchedInput = taskInputs.find(
                  (input) =>
                    input.parameterId === matchInput.simulationfactorId &&
                    input.imperialStandardValue.value ===
                      matchInput.value.imperialStandardValue.value &&
                    input.industryStandardValue.value ===
                      matchInput.value.industryStandardValue.value
                );
                if (matchedInput) {
                  matchedInputs++;
                }
              }

              if (matchedInputs === currentInputs.length) {
                matchingRunId.current = IN_PROGRESS_ADV_FACTOR_RUN;
                matchingRunFoundInResults = true;
                break; // Break out of the loop when a match is found
              }
            }
          }
        }

        if (!matchingRunFoundInResults) {
          matchingRunId.current = NOT_COMPUTED_RUN;
        }
      } else {
        matchingRunId.current = IN_PROGRESS_BASE_RUN;
      }
      console.log('matchingRunId.current', matchingRunId.current);
      setRunId(matchingRunId.current);
    }
  }, [currentAdvancedFactorValues, data, tasks]);
};
