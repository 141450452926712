import React, { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock.js';
import HighchartsReact from 'highcharts-react-official';
import {
  performanceStackedChartOptions,
  yScaleFormaterNoBenchmarks,
} from '../../charts/chartOptions';
import { MetricChartType } from '../../charts/types';
import _ from 'lodash';
import { CardContentContext } from './base/BaseCard';
import { AlertS, InfoS } from '@weave-mui/icons-weave';
import { Benchmark, DataPointValue, MetricEvaluationResult } from '../../types/metrics';
import * as conv from '../../conversions';
import i18n from '../../i18n';
import Box from '@weave-mui/box';
import { simpleCardDataStyles } from './base/Card.stylesheet';
import { EllipsisTypography } from '../../shared/EllipsisTypography';
import { InfoTooltipIcon } from '../../shared/InfoTooltipIcon';
import { tooltipPlacement } from '@weave-mui/enums';
import { MetricPopupLegend, MetricPopupLegendProps } from './shared/MetricPopupLegend';
import {
  benchmarkSeriesStructure,
  chartXValue,
  putBenchmarksOnChart,
  scaleYAxis,
} from '../../charts/benchmarkChartUtils';
import { MetricCardData } from '../../types/layout';

type MetricCardProps = {
  isPreview?: boolean;
};

export const MetricCard: React.FC<MetricCardProps> = ({ isPreview }) => {
  const { data: cardData, settings: cardSettings, cardId } = useContext(CardContentContext) || {};
  const [scaleYAxisAnimation, setScaleYAxisAnimation] = useState(false)
  const [popupLegendProps, setPopupLegendProps] = useState<MetricPopupLegendProps>({
    isVisible: false,
    selectedDataSeries: '',
    chartData: undefined,
    anchor: undefined,
  });
  const [chart, setChart] = useState<MetricChartType>(null);
  const [highChartOptions, setHighChartOptions] = useState(null);
  const animationFinished = useRef(false);

  const {
    value,
    unitSymbol,
    name,
    breakdown,
    isPartialResult,
    warningMessage,
    isComputed,
    benchmarks,
  } = cardData as DataPointValue;

  const { selectedBenchmarkIds } = cardSettings as MetricCardData;

  const metricResult = (value as MetricEvaluationResult)?.result;
  const isNegative = metricResult && metricResult < 0;
  const metricValue = (metricResult && conv.round(metricResult, 2)) || 0;
  const metricLabel = !isComputed
    ? i18n.t('cardConfig.notApplicableText')
    : ((metricResult && conv.round(metricResult, 2)) || 0).toLocaleString();
  const metricHasBreakdown = !!(breakdown && breakdown?.length);
  const subTitle = isComputed ? unitSymbol : '';
  const chartRef = useRef(null);
  const benchmarkSeries = [];

  const getSectionTitle = (chart: MetricChartType, type: string) => {
    if (type === benchmarkSeriesStructure.type) {
      //benchmark type
      return i18n.t('analysis.dataPoints.dropdownValues.benchmarks');
    }
    return `${chart.name} - ${chart.unit}`;
  };

  useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (chartRef.current) {
          chartRef.current.chart.reflow();
        }
      }, 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (!chart) {
      return;
    }

    // do not scale y axis between a  N/A graph state and a populated graph state
    if (!isComputed) {
      setScaleYAxisAnimation(false);
      chart.series.map((s) => s.setData([]));
    } else if (scaleYAxisAnimation) {
      scaleYAxis(chart);
    } else {
      setScaleYAxisAnimation(true);
    }
  }, [metricValue, benchmarks, chartRef, selectedBenchmarkIds, chart, isComputed, scaleYAxisAnimation]);

  useEffect(() => {
    if(!isComputed) return;
    if (chart) {
      const legendChartData = {
        ...chart,
        data: chart.data.reduce((acc, currentValue) => {
          const section = getSectionTitle(chart, currentValue.type) ?? chart.name;
          const series = chartRef?.current?.chart.series.find(
            (serie) => serie.name === currentValue.name
          );
          const color = currentValue.type === 'scatter' ? null : series?.color;
          const chartItem = { ...currentValue, color };
          if (!acc.hasOwnProperty(section)) {
            acc[section] = [chartItem]; //no color for benchmarks
          } else {
            acc[section].push(chartItem);
          }
          return acc;
        }, {}),
      };
      setPopupLegendProps((prevState) => {
        return {
          ...prevState,
          chartData: legendChartData,
          anchor: chartRef?.current?.chart.seriesGroup.element,
        }
      });
    }
  }, [chart, isComputed]);

  useEffect(() => {
    if(!isComputed) return;
    if (benchmarks && benchmarks?.length > 0) {
      benchmarks
        .filter(
          (benchmark) =>
            selectedBenchmarkIds?.findIndex((selectedId) => selectedId === benchmark.id) !== -1
        )
        .forEach((benchmark: Benchmark) => {
          benchmarkSeries.push({
            ...benchmarkSeriesStructure,
            id: benchmark.id,
            name: benchmark.displayName,
            data: [[chartXValue, benchmark.value]],
          });
        });
    }

    let chart: MetricChartType = {
      name,
      mainValue: metricValue,
      unit: unitSymbol,
      showChartLegend: metricHasBreakdown,
      data: (metricHasBreakdown && [
        ...breakdown
          .filter((item) => !!item.value)
          .map((item) => ({ name: item.name, pointStart: 1, data: [item.value] })),
        ...benchmarkSeries,
      ]) || [
        {
          name,
          pointStart: 1,
          data: [metricValue],
        },
        ...benchmarkSeries,
      ],
      displayWarning: isPartialResult,
      warningMessage: warningMessage,
    };

    setChart(chart);

    const options = _.cloneDeep(performanceStackedChartOptions);
    options.plotOptions.column.pointPlacement = (metricHasBreakdown && -0.3) || -0.1;
    options.series = chart.data;
    options.xAxis.categories = [chart.name];

    options.chart.events = {
      render: function () {
        const chart = this;
        putBenchmarksOnChart(chart);
      }
    };
    options.legend = {
      ...options.legend,
      enabled: false,
    };
    options.tooltip = {
      ...options.tooltip,
      enabled: false,
    };

    options.plotOptions.series.events = {
      afterAnimate: function () {
        animationFinished.current = true;
      },
    };
    options.plotOptions.series.point.events = {
      mouseOver: function (_) {
        if (!animationFinished.current) return;
        if (popupLegendProps.selectedDataSeries !== this.series.name) {
          setPopupLegendProps((prevState) => {
            return {
              ...prevState,
              selectedDataSeries: this.series.name,
              isVisible: true,
            };
          });
        }
        return false;
      },

      mouseOut: function (_) {
        if (!animationFinished.current) return;
        setPopupLegendProps((prevState) => {
          return {
            ...prevState,
            isVisible: false,
          };
        });
      },
    };

    if (isPreview) {
      // disable all interactivity in preview mode
      options.plotOptions.series.enableMouseTracking = false;
    }

    options.yAxis.labels = {
      ...options.yAxis.labels,
      formatter: yScaleFormaterNoBenchmarks(value, isNegative),
    };

    setHighChartOptions(options);
  }, [cardData, cardSettings]);

  const renderBarChartMetricData = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          paddingTop: '8px',
          paddingRight: '4px',
          paddingBottom: '36px',
          paddingLeft: '0px',
        }}
      >
        {(isComputed && chart.displayWarning && (
          <InfoTooltipIcon
            icon={
              <AlertS
                sx={{ paddingRight: '5px', paddingBottom: '4px', cursor: 'pointer' }}
                color="warning"
              />
            }
            tooltipContent={chart?.warningMessage}
            tooltiPlacement={tooltipPlacement.BOTTOM_START}
            maxWidth={'240px'}
          />
        )) || <></>}
        {(!isComputed && (
          <InfoTooltipIcon
            icon={
              <InfoS
                sx={{ paddingRight: '5px', paddingBottom: '4px', cursor: 'pointer' }}
                color="info"
              />
            }
            tooltipContent={i18n.t('cardConfig.requireSimulationText')}
            tooltipTitle={i18n.t('cardConfig.requireSimulation')}
            tooltiPlacement={tooltipPlacement.BOTTOM_START}
            maxWidth={'300px'}
          />
        )) || <></>}
        <EllipsisTypography
          mainValue={metricLabel}
          secondaryValue={subTitle}
          enableTooltip={true}
        />
      </Box>
    );
  };

  const renderHighCharts = () => {
    return (
        <div className="performance-chart" style={{ overflow: 'visible' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={highChartOptions}
            immutable={!cardId} //this fixes the issue when switching between metric in configure card. It ensures that the chart is re initialized when the options are changed
            ref={chartRef}
          />
          {chartRef?.current && popupLegendProps.chartData && cardId && (
            <MetricPopupLegend props={popupLegendProps} />
          )}
        </div>
    );
  };

  return (
    <Box sx={{ ...simpleCardDataStyles }}>
      {(highChartOptions && (
        <>
          {renderBarChartMetricData()}
          {renderHighCharts()}
        </>
      )) || <></>}
    </Box>
  );
};
