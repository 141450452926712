import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AnalysisRunsState } from '../../../state/slice/analysis-runs-slice';
import { useModelId } from '../useModelId';

const areSimulationsRunningSelector = createSelector(
  [(state: { analysisRuns }) => state.analysisRuns, (_, props) => props.modelId],
  (analysisRuns: AnalysisRunsState, modelId) => {
    if (!modelId || !analysisRuns?.hasOwnProperty(modelId)) {
      return false;
    }

    const ecAnalysisRuns = analysisRuns[modelId].ec.runs;
    const energyAnalysisRuns = analysisRuns[modelId].energy.runs;

    return Object.keys(ecAnalysisRuns).some(
      (key) => ecAnalysisRuns[key].job.status === 'RUNNING'
    ) || Object.keys(energyAnalysisRuns).some(
      (key) => energyAnalysisRuns[key].job.status === 'RUNNING'
    );
  }
);

export const useAreSimulationsRunning = (modelId?: string): boolean => {
  const id = modelId ?? useModelId();
  return useSelector((state) => areSimulationsRunningSelector(state, { modelId: id }));
};
