import { JobEventStatusEnum, NotificationType } from "../../notifications/acpEventServiceManager";
import {ComputeJobStatus} from "../api/task-service-api";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type AnalysisRunsState = Record<
  string,
  Record<
    string,
    { runs: Record<string, ComputeJobStatus>}
  >
>;

const initialState: AnalysisRunsState = {};
export type UpdateRunRequest = {
  base64RawURLEncodedUrn: string;
  status: JobEventStatusEnum
  modelId: string;
  jobType: 'ecsimulation' | 'energysimulation' | 'unknown';
  isBaseRun: boolean;
  notificationType: NotificationType;
};
export type AddJobStatusAction = {
  modelId: string;
  runs: ComputeJobStatus[];
};

export const analysisRunsSlice = createSlice({
  name: 'analysisRuns',
  initialState,
  reducers: {
    addOrUpdateAnalysisRuns: (state, action: PayloadAction<AddJobStatusAction>) => {
      const { modelId, runs: jobs } = action.payload;
      if (!state[modelId]) {
        state[modelId] = {
          ec: {
            runs: { }
          },
          energy: {
            runs: { }
          }
        };
      }

      jobs.forEach(job => {
        switch (job.request.jobType) {
          case 'ecsimulation':
            state[modelId].ec.runs[job.job.urn] =  job;
            break;
          case "energysimulation":
            state[modelId].energy.runs[job.job.urn] =  job;
            break;
          default: //handle or other job statuses
            break;
        }
      });
    },
    updateAnalysisRun: (state, {payload}: PayloadAction<UpdateRunRequest>) => {
      const {base64RawURLEncodedUrn, status, modelId, jobType, isBaseRun, notificationType} = payload;

      if (notificationType === NotificationType.Request || notificationType === NotificationType.Worker) return;

      if (!state[modelId]) {
        state[modelId] = {
          ec: {
            runs: {}
          },
          energy: {
            runs: {}
          }
        }
      }

      const jobState = jobType === 'ecsimulation' ? state[modelId].ec.runs : state[modelId].energy.runs;

      if (!jobState[base64RawURLEncodedUrn]) {
        jobState[base64RawURLEncodedUrn] = {
          job: {
            urn: base64RawURLEncodedUrn,
            status: null
          },
          request: {
            base64RawURLEncodedUrn: '',
            jobType,
            payload: {
              isBaseRun,
              modelId
            },
            status: 'FULFILLED'
          }
        }
      }

      jobState[base64RawURLEncodedUrn].job.status = status;
    },
    clearAnalysisRuns: (state, action: PayloadAction<{modelId: string}>) => {
      const { modelId } = action.payload;
      state[modelId] = {
        ec: {
          runs: {}
        },
        energy: {
          runs: {}
        }
      };
    }
  }
});

export const {
  addOrUpdateAnalysisRuns,
  updateAnalysisRun,
  clearAnalysisRuns,
} = analysisRunsSlice.actions;
