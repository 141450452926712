import React, { useCallback, useEffect } from 'react';
import i18n from '../i18n';
import { ToolTipButtonWrapper } from './ToolTipButtonWrapper';
import { useDataPointsV2 } from '../layout/hooks/v2/useDataPointsV2';
import { getAdvancedFactorDefinitionAndValuesFromSimulationFactorId } from '../layout/utils/dataPointsResultsUtils';
import { addNotification } from '../state/slice/notifications-slice';
import { useDispatch } from 'react-redux';
import { taskServiceApi, useSubmitTaskMutation } from '../state/api/task-service-api';
import { FAKE_MODEL_ID, FAKE_RUNNING_JOBS, simulationsTagType } from '../state/api/api-constants';
import { useIsSimulateButtonDisabled } from '../layout/hooks/simulation/useIsSimulateButtonDisabled';
import { Dashboard, StandardDashboard } from '../types/layout';
import { useModelId } from '../layout/hooks/useModelId';
import { addOrUpdateAnalysisRuns, clearAnalysisRuns } from '../state/slice/analysis-runs-slice';
import { useAreAllSimulationCombinationsDone } from '../layout/hooks/simulation/useAreAllSimulationCombinationsDone';

export const SubmitSimulation: React.FC<{
  currentDashboard: Dashboard | StandardDashboard;
  disabled?: boolean;
}> = ({ disabled, currentDashboard }) => {
  const dispatch = useDispatch();
  const [submitSimulationRequest] = useSubmitTaskMutation();
  const { data: dataPointsResult, isSuccess: isGetDataPointsResultSuccess } = useDataPointsV2();
  const isDisabled = useIsSimulateButtonDisabled(disabled, currentDashboard, dataPointsResult);
  const modelId = useModelId();
  const [pressed, setPressed] = React.useState(false);
  const areAllSimulationsDone = useAreAllSimulationCombinationsDone(dataPointsResult);  

  const onSimulateChange = useCallback(async () => {
    // disable the 'Simulate' button
    setPressed(true);
    // only if all simulation combinations have been run, this happens
    if (areAllSimulationsDone) {
      // dispatch a fake RUNNING job so that the 'Simulating...' spinner is shown
      dispatch(addOrUpdateAnalysisRuns({ runs: FAKE_RUNNING_JOBS, modelId: FAKE_MODEL_ID }));
      // after 3.5 seconds, remove that fake RUNNING job and enable the 'Simulate' button
      setTimeout(() => {
        dispatch(clearAnalysisRuns({ modelId: FAKE_MODEL_ID }));
        setPressed(false);
        // show Success toast
        dispatch(
          addNotification({
            message: i18n.t('simulation.notifications.energy.allSimulationsSuccessTitle'),
            type: 'success',
            autoHideDuration: 3500,
          })
        );
      }, 3500);
      return;
    }

    const simulationInfo = dataPointsResult.definitions?.advancedFactors
      ?.filter((definition) => definition?.simulationFactor)
      .map((definition) =>
        getAdvancedFactorDefinitionAndValuesFromSimulationFactorId(
          definition?.simulationFactor,
          dataPointsResult
        )
      )
      .find(
        ({ simulationFactorDefinition }) =>
          simulationFactorDefinition.simulationParameter.type === 'lpd'
      );

    if (
      !simulationInfo ||
      !simulationInfo?.simulationFactorDefinition ||
      !simulationInfo?.values?.length
    ) {
      setPressed(false);
      dispatch(
        addNotification({
          message: 'Error Submitting Simulation Task: Invalid simulation parameter',
          type: 'error',
          autoHideDuration: 3500,
        })
      );
      return;
    }

    const { simulationFactorDefinition, values: factorValues } = simulationInfo;
    const { simulationParameter } = simulationFactorDefinition;


    try {
      if (factorValues.length) {
        const industryStandardValueInput = {
          typeId: factorValues[0].industryStandardValue.typeId,
          value: factorValues.map((factorValue) => factorValue.industryStandardValue.value),
        };
        const imperialStandardValueInput = {
          typeId: factorValues[0].imperialStandardValue.typeId,
          value: factorValues.map((factorValue) => factorValue.imperialStandardValue.value),
        };
        await submitSimulationRequest({
          modelId,
          isBaseRun: false,
          inputs: [
            {
              parameterId: simulationFactorDefinition.id, //send the underlying simulation param id (not the advanced factor as this can be removed by the user)
              industryStandardValue: industryStandardValueInput,
              imperialStandardValue: imperialStandardValueInput,
              type: simulationParameter.type,
            },
          ],
        }).unwrap();

        // notify start of simulation
        dispatch(
          addNotification({
            message: 'Submitted Simulation Task.',
            type: 'info',
            autoHideDuration: 3500,
          })
        );
        dispatch(taskServiceApi.util.invalidateTags([{ type: simulationsTagType, id: modelId }]));
      }
    } catch (error) {
      setPressed(false);
    }
  }, [modelId, dataPointsResult, areAllSimulationsDone]);

  useEffect(() => {
    setPressed(false);
  }, [isDisabled]);

  return (
    (isGetDataPointsResultSuccess && (
      <ToolTipButtonWrapper
        toolTipTitle={i18n.t('topnav.buttons.simulate')}
        description={i18n.t('topnav.buttons.descriptionSimulation')}
        onButtonClickCallBack={onSimulateChange}
        buttonTitle={i18n.t('topnav.buttons.simulate')}
        disabled={pressed || isDisabled}
      ></ToolTipButtonWrapper>
    )) || <> </>
  );
};
