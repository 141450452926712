import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQuery, QueryType } from './base-queries';
import {
  POST, simulationsTagType,
  simulationsTagTypes
} from './api-constants';
import i18n from "../../i18n";
import { JobEventStatusEnum } from '../../notifications/acpEventServiceManager';

interface IndustryStandardValues {
  value: number[];
  typeId: string;
}

export type TaskInputs = {
  parameterId: string;
  industryStandardValue?: IndustryStandardValues;
  imperialStandardValue?: IndustryStandardValues;
  type: string; //'lpd' | 'building-orientation' | 'plug-loads'
};
export type TaskRequest = {
  modelId: string;
  isBaseRun: boolean;
  inputs?: Array<TaskInputs>;
}

export type RequestStatus = 'ACCEPTED' | 'REJECTED' | 'FAILED' | 'UNKNOWN' | 'CANCELED' | 'FULFILLED';
export type JobStatus = 'COMPLETED' | 'FAILED' | 'RUNNING' | 'UNKNOWN' | 'CANCELED' | 'TIMED_OUT';
export type ComputeJobStatus = {
  request: {
    base64RawURLEncodedUrn: string;
    status: RequestStatus;
    payload: TaskRequest;
    jobType: 'ecsimulation' | 'energysimulation' | 'unknown';
  };
  job?: {
    urn: string;
    status: JobEventStatusEnum;
  };
};

export const taskServiceApi = createApi({
  reducerPath: 'taskServiceApi',
  baseQuery: BaseQuery(QueryType.TaskService),
  keepUnusedDataFor: 3600,
  tagTypes: simulationsTagTypes,
  endpoints: (builder) => ({
    submitTask: builder.mutation<void, TaskRequest>({
      query: (body) => ({
        url: `/v1/task/:energy-simulation`,
        method: POST,
        body,
      }),
      extraOptions: {
        errorOptions: {
          notificationType: 'toast',
          messageOverride: {
            title: i18n.t('simulation.notifications.energy.allSimulationsFailedTitle'),
            content: i18n.t('simulation.notifications.allSimulationsFailedMessage'),
          },
          autoHideDuration: 86400000, // 24 hours
        },
      },
    }),
    getTasksForModelId: builder.query<Array<ComputeJobStatus>, string>({
      query: (modelId) => `/v1/task/${modelId}`,
      providesTags: (_, __, modelId) => [{ type: simulationsTagType, id: modelId }],
    }),
    getChannelForModel: builder.query<any, string>({
      query: (modelId: string)=> `/v1/channel/${modelId}`
    })
  }),
});

export const {
  useSubmitTaskMutation,
  useGetTasksForModelIdQuery,
  useLazyGetChannelForModelQuery
} = taskServiceApi;
