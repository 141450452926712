import React, { FC, useEffect, useRef, useState } from 'react';
import { useCardGrid } from '@adsk/wildcard';
import { EditS, InfoS } from '@weave-mui/icons-weave';
import Button from '@weave-mui/button';
import Typography, { typographyVariants } from '@weave-mui/typography';
import {
  controlPanelStyles,
  controlPanelAddCardsToggleWrapper,
  controlPanelDividerWrapper,
  controlPanelDividerStyles,
  controlPanelHeaderDividerStyles,
  headerIconStyle,
  footerButtonStyle,
} from './LayoutGrid.stylesheet';
import i18n from '../i18n';
import Box from '@weave-mui/box';
import Divider, { dividerOrientations, dividerVariants } from '@weave-mui/divider';
import Switch from '@weave-mui/switch';
import { FormControlLabel } from '@mui/material';
import Modal from '@weave-mui/modal';
import { CardRegistry } from './cards/card-registry';
import { Dashboard, StandardDashboard, WildCardBaseSettings } from '../types/layout';
import { useLayoutContext } from './LayoutContext';
import { diff } from 'deep-object-diff';
import { useGetDashboardsQuery } from '../state/api/dashboard-api';
import LayoutGridCardLibrary from './LayoutGridCardLibrary';
import { cardSettingsDiff } from './utils/compare-utils';
import { useGetUserFeatureFlagDataQuery } from '../state/api/feature-flag-data-api';
import { SubmitSimulation } from '../shared/SubmitSimulation';

type LayouGridControlPanelProps = {
  disableEditButton?: boolean;
  isDashboardUpdating?: boolean;
  onScroll?: Function;
  currentDashboard: Dashboard | StandardDashboard;
};

export const LayouGridControlPanel: FC<LayouGridControlPanelProps> = ({
  disableEditButton = false,
  isDashboardUpdating = false,
  onScroll,
  currentDashboard,
}) => {
  const { data: featureFlagData } = useGetUserFeatureFlagDataQuery();
  const dataPointsV2Enabled = featureFlagData?.['use-v2-data-point-api'] as boolean;
  const { state, toggleCustomizeMode, onSave, resetCurrentLayout, toggleCardLibrary, removeCard } =
    useCardGrid();
  const { data: dashboards } = useGetDashboardsQuery();
  const { setIsEditModeActive, setIsDirty, currentState, setCurrentState } = useLayoutContext();
  const [open, setOpen] = useState(false);
  const [triggerSave, setTriggerSave] = useState(false);
  const addedCardsRef = useRef({});
  const saveInProgressRef = useRef(false);
  const isCardLibraryOpen = useRef(false);
  const [showEditDashboardButton, setshowEditDashboardButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowEditDashboardButton(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (state.customizeMode && !currentState) {
      setCurrentState({
        cards: structuredClone(
          state.allLayouts.desktop.map(({ i, w, h, x, y }) => ({ i, w, h, x, y }))
        ),
        settings: structuredClone(state.cardSettings),
      });
    }
    if (!state.customizeMode && currentState) {
      setCurrentState(null);
    }
  }, [setCurrentState, state, currentState]);

  useEffect(() => {
    setIsEditModeActive(state.customizeMode);

    if (currentState) {
      const cardsStateDiff = diff(
        currentState.cards.map(({ w, h, x, y }) => ({ w, h, x, y })),
        state.allLayouts.desktop.map(({ w, h, x, y }) => ({ w, h, x, y: isFinite(y) ? y : 0 })) //we are setting 0 here because wildcard has a tendency of writing Infinity for y when it's adding first and only card
      );
      const settingsStateDiff = cardSettingsDiff(currentState.settings, state.cardSettings);
      setIsDirty(
        Object.entries(cardsStateDiff).length > 0 || Object.entries(settingsStateDiff).length > 0
      );

      const cardsStatesHash = Object.values(cardsStateDiff)
        .map((diff) => diff?.i)
        .filter((i) => !!i)
        .reduce((hash, i) => ({ ...hash, [i]: true }), {});
      const addedCardsDiff = diff(addedCardsRef.current, cardsStatesHash);
      if (Object.entries(addedCardsDiff).length > 0) {
        // const elementToScroll = Object.keys(addedCardsDiff).find((i) => {
        //   const el = document.querySelector<HTMLElement>(`[data-testid="${i}"]`);
        //   return el ? !isElementVisible(el) : false;
        // });
        // if (elementToScroll) {
        //   onScroll?.(elementToScroll);
        // }
        addedCardsRef.current = { ...addedCardsRef.current, ...addedCardsDiff };
      }
    }
  }, [state, currentState, setIsEditModeActive, setIsDirty, setCurrentState, onScroll]);

  useEffect(() => {
    if (triggerSave && !saveInProgressRef.current && !isDashboardUpdating) {
      setTriggerSave(false);
      saveInProgressRef.current = true;
      onSave();
    }
  }, [state, triggerSave, onSave, isDashboardUpdating]);

  useEffect(() => {
    if (!triggerSave && saveInProgressRef.current && !isDashboardUpdating) {
      saveInProgressRef.current = false;
    }
  }, [state, triggerSave, isDashboardUpdating]);

  useEffect(() => {
    if (state.customizeMode) {
      if (
        Object.keys(state?.cardSettings).length === 0 &&
        !state.cardLibraryOpen &&
        !isCardLibraryOpen.current
      ) {
        toggleCardLibrary();
        isCardLibraryOpen.current = true;
      }
    } else {
      //Reset the open state when the toggle is clicked for empty dashboard
      if (Object.keys(state?.cardSettings).length === 0 && isCardLibraryOpen.current) {
        isCardLibraryOpen.current = false;
      }
    }
  }, [state, toggleCardLibrary]);

  const handleClose = () => {
    setOpen(false);
  };

  const getUnconfiguredCards = () =>
    Object.entries(state.cardSettings).reduce((currentKeys, [key, setting]) => {
      const cardRegistryEntry = CardRegistry[setting.type];
      let cardHasRequiredSettings =
        cardRegistryEntry?.cardConfigurationSettings.cardHasRequiredSettingsToDisplay(
          setting as WildCardBaseSettings
        );

      if (setting.type === 'freeEntryText') {
        if (!setting.text || setting.text?.trim().length === 0) {
          cardHasRequiredSettings = false;
        }
      }
      if (!cardHasRequiredSettings) {
        return [...currentKeys, key];
      }
      return currentKeys;
    }, []);

  const handleConfirm = () => {
    const dismissedCards = getUnconfiguredCards();
    dismissedCards.forEach((key) => removeCard(key));
    const remainingCards = state.allLayouts.desktop.filter(({ i }) => !dismissedCards.includes(i));
    if (remainingCards.length > 0) {
      // since we are removing the cards we need to force an update on the cardgrid state
      // calling onSave here will trigger the save process but with outdated version of the state
      setTriggerSave(true);
    }
    onCustomize();
    setOpen(false);
  };

  const onCustomize = () => {
    toggleCustomizeMode();
  };

  const onCustomSave = () => {
    const shouldShowUnconfiguredModal = getUnconfiguredCards().length > 0;
    if (shouldShowUnconfiguredModal) {
      setOpen(true);
    } else {
      onSave();
      onCustomize();
    }
  };

  const onResetCurrentLayout = () => {
    resetCurrentLayout();
    onCustomize();
  };

  const ontoggleCardLibrary = () => {
    toggleCardLibrary();
  };

  const renderModalContent = () => {
    const buttonBox = (
      <Box>
        <Box
          gap={3}
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            {i18n.t('tabConfiguration.editModel.unconfiguredCancelButton')}
          </Button>
          <Button onClick={handleConfirm}>
            {i18n.t('tabConfiguration.editModel.unconfiguredOkButton')}
          </Button>
        </Box>
      </Box>
    );

    return (
      <>
        <Modal
          header={
            <Typography variant={typographyVariants.H3_MEDIUM}>
              <InfoS color="info" style={headerIconStyle} />
              {i18n.t('cardConfig.unconfiguredCardTitle')}
            </Typography>
          }
          open={open}
          onClose={handleClose}
          actions={buttonBox}
          sx={{
            '& .MuiModalWeave-box': {
              width: '400px',
            },
          }}
        >
          {' '}
          <Typography>{i18n.t('cardConfig.unconfiguredCardText')}</Typography>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Box
        sx={
          dataPointsV2Enabled
            ? controlPanelStyles
            : { ...controlPanelStyles, justifyContent: 'end' }
        }
      >
        <Box sx={{ alignSelf: 'center', display: 'flex', alignItems: 'center', gap: '8px' }}>
          {dataPointsV2Enabled && !state.customizeMode && (
            <SubmitSimulation currentDashboard={currentDashboard} disabled={disableEditButton} />
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {!state.customizeMode && (
            <>
              {dashboards?.length === 1 && (
                <>
                  <Typography sx={{ fontweight: '600' }} variant="body1Medium">
                    {i18n.t('dashboardControls.createDashboardText')}
                  </Typography>
                  <Divider
                    orientation={dividerOrientations.VERTICAL}
                    variant={dividerVariants.FULLWIDTH}
                    sx={controlPanelHeaderDividerStyles}
                  />
                </>
              )}
              {showEditDashboardButton && (
                <Button
                  disabled={disableEditButton}
                  onClick={onCustomize}
                  variant="outlined"
                  startIcon={<EditS />}
                >
                  {i18n.t('dashboardControls.editDashboardButton')}
                </Button>
              )}
            </>
          )}
          {state.customizeMode && (
            <>
              <Box sx={controlPanelAddCardsToggleWrapper}>
                <FormControlLabel
                  sx={{
                    m: 0,
                  }}
                  control={
                    <Switch
                      checked={state.cardLibraryOpen}
                      onClick={ontoggleCardLibrary}
                      sx={{ width: '40px' }}
                    />
                  }
                  label={
                    <Typography sx={{ fontweight: '600' }} variant="body1Medium">
                      {i18n.t('dashboardControls.cardLibrary')}
                    </Typography>
                  }
                />
              </Box>
              <Box sx={controlPanelDividerWrapper}>
                <Divider
                  orientation={dividerOrientations.VERTICAL}
                  variant={dividerVariants.FULLWIDTH}
                  sx={controlPanelDividerStyles}
                />
              </Box>
              <Button onClick={onResetCurrentLayout} variant="outlined">
                <Typography sx={{ fontweight: '600' }} variant="body1Medium">
                  {i18n.t('dashboardControls.cancelButton')}
                </Typography>
              </Button>
              <Button onClick={onCustomSave} sx={{ ...footerButtonStyle, ml: '8px' }}>
                <Typography sx={{ color: '#FFFFFF', fontweight: '600' }} variant="body1Medium">
                  {i18n.t('dashboardControls.doneButton')}
                </Typography>
              </Button>
            </>
          )}
        </Box>
      </Box>
      {(state.customizeMode && <LayoutGridCardLibrary />) || <></>}
      {renderModalContent()}
    </>
  );
};
